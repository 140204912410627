import { Divider, Fab, LinearProgress, Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import MapIcon from '@material-ui/icons/Map';
import Link from 'components/Link';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import BottomNavSales from 'sales/BottomNavSales';
import { useCurrencyFormatter, useDate, useSettings } from 'utils/customHooks';
import fetch from 'utils/fetch';
import {
  format0,
  formatDayTime,
  formatDayTime24,
  formatTimeSpan,
  isVal,
  truncate,
} from 'utils/utils';
import { formatCurrency0 } from '../utils/utils';

const useStyles = makeStyles((theme) => ({
  showLegend: {
    position: 'fixed',
    bottom: 80,
    right: 20,
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    width: 50,
    borderRadius: 25,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

const SalesDashboard = ({ salesmanId }) => {
  const date = useDate();
  const { data, isFetching } = useQuery(['salesDasboard_RetailerView', salesmanId], () =>
    fetch(`/v3/salesman/${salesmanId}/retailers?date=${date}`)
  );
  const [openLegend, setOpenLegend] = useState(false);
  const [retailers, setRetailers] = useState();

  const classes = useStyles();

  useEffect(() => {
    setRetailers(
      data?.retailers?.sort((a, b) => {
        if (!b.status) return -1;
        if (!a.status) return 1;
        return new Date(a.status.timestamp) <= new Date(b.status.timestamp) ? -1 : 1;
      })
    );
  }, [data]);

  return (
    <>
      {isFetching && <LinearProgress className="fixed top-12 w-full" color="secondary" />}
      <div className="p-4 pb-20">
        <div className="text-lg py-4 pb-2 ">Beat Retailers</div>
        <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-3">
          {retailers
            ?.filter((r) => !r.offBeat)
            ?.map((retailer) => (
              <RetailerCard
                retailer={retailer}
                salesmanId={data?.id}
                key={retailer.id}
              ></RetailerCard>
            ))}
        </div>
        <div className="text-lg pb-2 pt-6 ">Off Beat Retailers</div>
        <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-3">
          {retailers
            ?.filter((r) => r.offBeat)
            ?.map((retailer) => (
              <RetailerCard
                retailer={retailer}
                salesmanId={data?.id}
                key={retailer.id}
              ></RetailerCard>
            ))}
        </div>
      </div>

      <Link to={`/sales/salesmanTracking/${salesmanId}`}>
        <IconButton className="fixed bottom-36 right-5 bg-primary text-white">
          <MapIcon />
        </IconButton>
      </Link>

      {openLegend ? (
        <div
          class="fixed bottom-20 right-5 bg-white p-1 border rounded-lg cursor-pointer"
          onClick={() => setOpenLegend(false)}
        >
          <Legend />
        </div>
      ) : (
        <Fab
          aria-label="btn"
          className={classes.showLegend}
          variant="circular"
          onClick={() => setOpenLegend(true)}
        >
          <HelpOutlineIcon fontSize="large" />
        </Fab>
      )}
      <BottomNavSales index={'none'} />
    </>
  );
};

const retailerCardFields = {
  INVOICE_VALUE: {
    label: 'Invoice Value',
    value: (r) => (isVal(r.invoiceValue) ? formatCurrency0(r.invoiceValue) : '--'),
  },
  ORDER_TIME: {
    label: 'Order Time',
    value: (r) => (r.orderTime ? formatDayTime24(new Date(r.orderTime)) : '--'),
  },
  LINES: { label: 'Lines', value: (r) => r.lines },
  UNIQUE_BPD: { label: 'Total BPD', value: (r) => r.uniqueBrands },
  DGP: {
    label: 'DGP',
    value: (r) =>
      `${format0(r.dgpAchieved)}/${format0(r.dgpTarget)} (${format0(
        (r.dgpAchieved * 100) / r.dgpTarget
      )}%)`,
  },
  TARGET_BPM: { label: 'Target BPM', value: (r) => format0(r.bpmTarget) },
};
// const retailerViewCardFields1 = [
//   'INVOICE_VALUE',
//   'ORDER_TIME',
//   'LINES',
//   'UNIQUE_BPD',
//   'DGP',
//   'TARGET_BPM',
// ];

function RetailerCard({ retailer, salesmanId }) {
  const { code, name, status, salesValue, id, inStoreTime } = retailer;
  const formatCurrency = useCurrencyFormatter();
  const { retailerViewCardFields } = useSettings();
  const hoverStatus = name.length >= 25;

  let color = 'gray';
  if (status) {
    if (status.state === 'visited') color = '#10B981';
    else if (status.state === 'passed') color = '#FBBF24';
    else if (status.state === 'not-visited') color = '#EF4444';
  }
  return (
    <Link to={`/sales/Past_Invoices/${id}/${salesmanId}`}>
      <div
        class={`bg-white rounded-lg p-3 shadow h-full border-l-8`}
        style={{ borderColor: color }}
      >
        <div class="flex justify-between">
          <Tooltip title={name} interactive disableHoverListener={!hoverStatus}>
            <p class="text-lg font-semibold">{truncate(name)}</p>
          </Tooltip>
          <div class="flex">
            {isVal(status?.waitTime) && retailerViewCardFields.includes('HEADER_WAIT_TIME') ? (
              <p class="text-grey">{`${formatTimeSpan(status?.waitTime / 1000)}`}</p>
            ) : null}
            {isVal(inStoreTime) && retailerViewCardFields.includes('HEADER_INSTORE_TIME') ? (
              <p class="text-grey">{`${formatTimeSpan(inStoreTime / 1000)}`}</p>
            ) : null}
            {isVal(status?.timestamp) && retailerViewCardFields.includes('HEADER_TIMESTAMP') ? (
              <p class="ml-2 whitespace-nowrap">{`${formatDayTime(
                new Date(status?.timestamp)
              )}`}</p>
            ) : null}
          </div>
        </div>
        <div class="flex justify-between">
          <p class="text-sm">{truncate(code)}</p>
          {isVal(salesValue) ? <p class="font-semibold">{formatCurrency(salesValue)}</p> : null}
        </div>
        <Divider class="my-1" />
        <div class="flex flex-wrap justify-between">
          {retailerViewCardFields.map(
            (f) =>
              retailerCardFields[f] && (
                <div class="m-1 text-center">
                  <div class="text-lg">{retailerCardFields[f]?.value(retailer) ?? '--'}</div>
                  <div class="text-grey">{retailerCardFields[f]?.label}</div>
                </div>
              )
          )}
        </div>
      </div>
    </Link>
  );
}

const markers = [
  { marker: '#10B981', text: 'Visited' },
  { marker: '#FBBF24', text: 'Passed' },
  { marker: '#EF4444', text: 'Not Visited' },
];

function Legend() {
  return (
    <div>
      <p class="text-center font-semibold text-md my-2">Markers</p>
      {markers.map((marker) => (
        <div class="flex justify-between items-center px-2 py-1">
          <div
            class="rounded-lg shadow border-8 h-full"
            style={{ borderColor: marker.marker }}
          ></div>
          <p class="ml-2 mt-1">{marker.text}</p>
        </div>
      ))}
    </div>
  );
}

export default SalesDashboard;
